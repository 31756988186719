import React from 'react';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const SignupForm = ({
  title,
  disclaimer,
  name,
  email,
  number,
  subject,
  message,
  submit
}) => {
  return (
    <section className="section">
      <div className="container kos__Contact">
        <Formik
          initialValues={{
            name: '',
            email: '',
            number: '',
            subject: '',
            message: ''
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
            message: Yup.string().required('Required')
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <Grid container>
              <Grid item xs={12}>
                <h3 className="kos__Contact__Disclaimer">{disclaimer}</h3>
                <h3 className="kos__Contact__Title">{title}</h3>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <label className="kos__Form__Label" htmlFor="name">
                  {name}
                </label>
                <Field className="kos__Form__Input" name="name" type="text" />
                <div className="kos__Form__Error">
                  <ErrorMessage name="name" />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="kos__Form__Label" htmlFor="email">
                  {email}
                </label>
                <Field className="kos__Form__Input" name="email" type="email" />
                <div className="kos__Form__Error">
                  <ErrorMessage name="email" />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <label className="kos__Form__Label" htmlFor="number">
                  {number}
                </label>
                <Field className="kos__Form__Input" name="number" type="text" />
                <div className="kos__Form__Error">
                  <ErrorMessage name="number" />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="kos__Form__Label" htmlFor="subject">
                  {subject}
                </label>
                <Field
                  className="kos__Form__Input"
                  name="subject"
                  type="text"
                />
                <div className="kos__Form__Error">
                  <ErrorMessage name="subject" />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <label className="kos__Form__Label" htmlFor="message">
                  {message}
                </label>
                <Field
                  className="kos__Form__TextArea"
                  name="message"
                  as="textarea"
                />
                <div className="kos__Form__Error">
                  <ErrorMessage name="message" />
                </div>
              </Grid>
            </Grid>
            <button
              type="submit"
              className="button is-rounded kos__btn--green kos__Form__Submit"
            >
              <span>{submit}</span>
              <ChevronRightIcon />
            </button>
          </form>
        </Formik>
      </div>
    </section>
  );
};

export default SignupForm;
